import ArrowUpIcon from 'assets/icon/arrow_up.svg'

const ToTop = () => {
  const scroll = () => {
    const scrollTop =
      document.documentElement.scrollTop ||
      window.pageYOffset ||
      document.body.scrollTop
    if (scrollTop !== 0) window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  return (
    <div
      className='mt-[48px] mx-auto text-center w-fit px-[12px] cursor-pointer'
      onClick={scroll}
    >
      <button className='m-auto w-[36px] h-[36px] rounded-full bg-label-l1 flex justify-center items-center'>
        <ArrowUpIcon className='fill-playseeGray-gray5 w-[24px] h-[24px]' />
      </button>
      <div className="text-lg mt-[8px] text-label-l1">TOP</div>
    </div>
  )
}

export default ToTop
