import { Accordion } from 'component/ui/accordion'

import { IJob } from 'types/job'
import List from './list'

export const Jobs = ({ jobs }: { jobs: IJob[] }) => {
  return (
    <Accordion
      type='multiple'
      className='w-full rounded-[20px] bg-background-1st shadow-floating-dropdown'
    >
      {jobs.map((job, index) => (
        <List key={job.name} {...{ job, index }} />
      ))}
    </Accordion>
  )
}
