import { RefObject } from 'react'

import { Api } from 'assets/api'
import { Jobs } from 'component/jobs'
import ToTop from 'component/to-top'
import { IJob } from 'types/job'
import { CDN_URL } from 'assets/constant'

const Index = ({
  jobs,
  landingRef
}: {
  jobs: IJob[]
  landingRef: RefObject<HTMLElement>
}) => {
  return (
    <>
      {/* Landing */}
      <section
        ref={landingRef}
        className='h-[200px] homePad:h-[400px] w-full overflow-hidden relative'
      >
        <img
          src={`${CDN_URL}/careers/images/about_landing_pic.jpg`}
          alt="Playsee's landing"
          width="1280"
          height="400"
          className='absolute top-0 left-0 object-cover w-full h-full'
        />
      </section>

      {/* Section 1 */}
      <section className='py-[48px] px-[24px] homePad:py-[96px] homePad:px-[180px] bg-[--community]'>
        <div className='homePad:max-w-[920px] homePad:mx-auto text-primary-on_primary'>
          <h2 className='text-[24px] leading-[30px] font-bold homePad:text-[60px] homePad:leading-[72px] homePad:max-w-[900px]'>
            Join Playsee
          </h2>
          <p className='mt-[16px] homePad:mt-[48px] homePad:text-xl text-md'>
            Millions of people around the world use Playsee to record and
            discover their next adventure.
            <br />
            <br />
            Since our launch, we have experienced exponential growth. As we
            continue to build our team, we are looking for talent across
            multiple verticals, including design, engineering, marketing,
            business operations and more.
            <br />
            <br />
            Are you up for the challenge?
          </p>
        </div>
      </section>

      {/* Section 2 */}
      <section
        id='jobs'
        className='py-[48px] px-[24px] homePad:py-[96px] homePad:px-[180px] scroll-mt-[48px] homePad:scroll-mt-[128px] '
      >
        <div className='w-full max-w-[640px] mx-auto'>
          <h2 className='text-[24px] leading-[30px] font-bold homePad:text-[60px] homePad:leading-[72px] homePad:text-center'>
            View Jobs
          </h2>
          <p className='mt-[16px] homePad:mt-[48px] mb-[48px] homePad:text-xl text-md'>
            We are an international team of creative thinkers and rule breakers
            who are passionate about elevating our product and bringing the best
            experience to our millions of users.
            <br />
            <br />
            Apply now at{' '}
            <a
              href='mailto:hr@playsee.co'
              target='_blank'
              rel='noopener noreferrer'
              className='text-[--branding]'
            >
              hr@playsee.co
            </a>
          </p>
          <Jobs jobs={jobs} />
        </div>
        <ToTop />
      </section>
    </>
  )
}

export const getServerSideProps = async () => {
  let jobs: IJob[] = []
  try {
    const { data } = (await Api.get('/career/jobs')) as {
      data: { jobs: IJob[] }
    }
    jobs = data.jobs.map(job => ({
      name: job.name,
      occupations: job.occupations
    }))
  } catch (error) {
    console.error(error)
  }

  return { props: { jobs } }
}

export default Index
