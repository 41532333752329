import Link from 'next/link'

import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger
} from 'component/ui/accordion'
import { IJob } from 'types/job'

interface ListProps {
  job: IJob
  index: number
}
const List = ({ job, index }: ListProps) => {
  const occupations = job.occupations.length

  const position = occupations === 1 ? 'position' : 'positions'

  return (
    <AccordionItem
      value={job.name}
      className='border-b-[4px] border-background-b1 first:mt-0 first:rounded-t-[20px] last:rounded-b-[20px] text-label-l1'
    >
      <AccordionTrigger className='p-[16px]' gtm-job={`job.no_${index}`}>
        <div className='text-xl font-semibold'>{job.name}</div>
        <div
          className='mr-[12px] ml-auto text-lg'
          data-count={job.occupations.length}
        >
          {occupations} <span className='hidden phone:inline'>{position}</span>
        </div>
      </AccordionTrigger>

      <AccordionContent className='bg-background-b1'>
        <div className='py-[8px]'>
          {job.occupations.length > 0 ? (
            <div className='flex flex-col'>
              {job.occupations.map(occupation => (
                <Link
                  key={occupation.id}
                  href={`/detail/${occupation.id}`}
                  className='text-lg tracking-[0.5px] py-[8px] px-[16px]'
                  gtm-occupation={`occupation.${occupation.id}`}
                >
                  {occupation.name}
                </Link>
              ))}
            </div>
          ) : (
            <div className='text-lg text-label-l3 px-[16px] py-[8px]'>
              Currently no available positions
            </div>
          )}
        </div>
      </AccordionContent>
    </AccordionItem>
  )
}

export default List
